<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Admin Payment</h2>
      <r-menu-tab :level="2" />
    </template>
    <template #action-bar>
      <div class="level action-bar-padding">
        <div class="level-left">
          <b-button
            type="is-primary"
            outlined
            icon-left="file-document"
            label="Export Excel"
            @click.native="exportExcel()"
            :loading="isExporting"
          />
        </div>
        <div class="level-right">
          <b-datepicker
            v-model="dates"
            icon="calendar-range"
            placeholder="Select Date Range"
            :icon-right="dates.length === 0 ? '' : 'close'"
            icon-right-clickable
            @icon-right-click="clearDate"
            @input="filterByDate()"
            class="filter-datepicker"
            range
          ></b-datepicker>
          <div v-if="statusArray.length !== 0" class="filter-active status">
            Status +{{ statusArray.length }}
            <span>
              <b-icon
                class="pointer-click"
                icon="close"
                custom-size="mdi-18px"
                @click.native="clearStatus()"
              />
            </span>
          </div>
          <div
            v-if="methodArray.length !== 0"
            class="filter-active payment-method"
          >
            Payment Method +{{ methodArray.length }}
            <span>
              <b-icon
                class="pointer-click"
                icon="close"
                custom-size="mdi-18px"
                @click.native="clearPayment()"
              />
            </span>
          </div>
          <b-button
            type="is-light"
            icon-left="filter-outline"
            class="mx-2"
            @click.native="openFilter"
          >
            <img
              src="@/assets/images/filter-dot.svg"
              v-if="statusArray.length !== 0 || methodArray.length !== 0"
              class="filter-dot-button"
            />
            Filter
          </b-button>
          <HistoryFilter
            :isFilterOpen="isFilterOpen"
            :statuses="statuses"
            :payMethods="payMethods"
            @closeFilter="closeFilter"
            @filterStatus="filterByStatus($event)"
            @filterPaymentMethod="filterByPayment($event)"
            @resetState="resetState"
            @loadData="loadHistoryPaymentIndex"
          />
          <b-input
            icon-right="magnify"
            v-model="search"
            placeholder="Search"
            @keydown.native.enter="searchIconClick(search)"
            icon-right-clickable
            @icon-right-click="searchIconClick(search)"
            :loading="isLoading"
          />
        </div>
      </div>
    </template>
    <template #page-content>
      <b-table
        :data="data ? data.data : []"
        :loading="isLoading"
        paginated
        backend-pagination
        backend-sorting
        :total="data && data.meta ? data.meta.total : 0"
        :per-page="perPage"
        :default-sort-direction="sortOrder"
        :default-sort="[sortField, sortOrder]"
        @sort="onSort"
        @page-change="onPageChange"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        :current-page.sync="page"
        class="table-admin-payment-management"
      >
        <b-table-column
          width="5%"
          field="id"
          label="Order ID"
          v-slot="props"
          sortable
        >
          <span>{{ props.row.id }}</span>
        </b-table-column>
        <b-table-column
          field="package"
          label="Packages"
          v-slot="props"
          width="12%"
        >
          <span>{{ props.row.package }}</span>
        </b-table-column>
        <b-table-column field="buyer" width="15%" label="Buyer" v-slot="props">
          <span>{{ props.row.buyerEmail ? props.row.buyerEmail : '-' }}</span>
        </b-table-column>
        <b-table-column
          field="payment_method"
          label="Payment Method"
          width="5%"
          v-slot="props"
        >
          <span class="is-capitalized">
            {{ props.row.paymentMethod ? props.row.paymentMethod : '-' }}
          </span>
        </b-table-column>
        <b-table-column
          field="product"
          width="3%"
          label="Product"
          v-slot="props"
        >
          <span>{{ props.row.product }}</span>
        </b-table-column>
        <b-table-column
          field="company"
          width="5%"
          label="Company"
          v-slot="props"
        >
          <span>{{ props.row.company }}</span>
        </b-table-column>
        <b-table-column
          field="payment_date"
          label="Date Payment"
          v-slot="props"
          sortable
          width="10%"
        >
          <span>
            {{
              props.row.paymentDate
                ? formatDate(props.row.paymentDate, 'DD MMMM YYYY')
                : '-'
            }}
          </span>
        </b-table-column>
        <b-table-column
          field="total_amount"
          label="Amount"
          width="10%"
          v-slot="props"
          sortable
        >
          <span>Rp. {{ formatCurrency(props.row.totalAmount) }}</span>
        </b-table-column>
        <b-table-column
          field="created_at"
          label="Billing Date Issue"
          v-slot="props"
          sortable
          width="15%"
        >
          <span>
            {{
              props.row.billingDate
                ? formatDate(props.row.billingDate, 'DD MMMM YYYY')
                : '-'
            }}
          </span>
        </b-table-column>
        <b-table-column field="status" label="Status" width="5%" v-slot="props">
          <div class="status is-capitalized" :class="props.row.status">
            {{ props.row.status }}
          </div>
        </b-table-column>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                No data yet, please contact us if you have any problems
                (hello@getrise.id)
              </p>
            </div>
          </section>
        </template>
      </b-table>
    </template>
  </r-page>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import HistoryFilter from '../../components/HistoryFilter.vue'
import moment from 'moment'
import downloadDocument from '@/views/AdminPayment/mixins/downloadDocument'
import indexingParams from '@/views/AdminPayment/mixins/indexingParams'
export default {
  components: { HistoryFilter },
  mixins: [downloadDocument, indexingParams],
  data() {
    return {
      isLoading: false,
      isExporting: false,
      search: '',
      data: null,
      perPage: 10,
      page: 1,
      lastPage: 1,
      total: 0,
      sortField: 'id',
      sortOrder: 'desc',
      isFilterOpen: false,
      statusArray: [],
      methodArray: [],
      dates: [],
      tooltipLabel: `When filter is activated, the text will change to bold
                      and the color will change to red.`,
      statuses: [
        {
          title: 'Unpaid',
          value: 'unpaid',
          isClicked: false,
        },
        {
          title: 'Cancelled',
          value: 'cancelled',
          isClicked: false,
        },
        {
          title: 'Refund',
          value: 'refund',
          isClicked: false,
        },
        {
          title: 'Paid',
          value: 'paid',
          isClicked: false,
        },
        {
          title: 'Pending',
          value: 'pending',
          isClicked: false,
        },
        {
          title: 'Expired',
          value: 'expired',
          isClicked: false,
        },
      ],
      payMethods: [
        {
          title: 'Card',
          value: 'card',
          isClicked: false,
        },
        {
          title: 'E-Wallet',
          value: 'e-wallet',
          isClicked: false,
        },
        {
          title: 'QRIS',
          value: 'qris',
          isClicked: false,
        },
      ],
      dateFrom: null,
      dateTo: null,
    }
  },

  async mounted() {
    this.setHistoryIndex([])
    await this.loadHistoryPaymentIndex()
  },

  computed: {
    ...mapGetters({
      getHistory: 'adminPayment/getHistoryIndex',
    }),
  },

  methods: {
    ...mapActions({
      fetchHistoryIndex: 'adminPayment/fetchHistoryIndex',
      exportReport: 'adminPayment/exportPaymentReport',
    }),

    ...mapMutations({
      setHistoryIndex: 'adminPayment/setHistoryIndex',
    }),

    formatCurrency(val) {
      return new Intl.NumberFormat('id-ID', {
        minimumFractionDigits: 0,
      }).format(val)
    },

    async exportExcel() {
      this.isExporting = true
      let params = [
        ...this.getParams({
          page: this.page,
          perPage: this.perPage,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          type: 'payment',
          additionalFilters: [this.statusArray, this.methodArray],
        }),
      ]

      await this.exportReport(params.join('&'))
        .then((response) => {
          this.downloadDocument({
            blobData: response.data,
            documentName: `payment-report.xlsx`,
          })

          this.$swal({
            icon: 'success',
            title: 'Download Success',
            text: 'Payment Report is successfully downloaded',
            timer: 4000,
            showConfirmButton: false,
          })
        })
        .catch(() => {
          this.$swal({
            icon: 'error',
            title: 'Download Failed !',
            text: `Failed to download payment report file !`,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'button is-danger',
            },
          })
        })
        .finally(() => {
          this.isExporting = false
        })
    },

    clearDate() {
      this.resetState()
    },

    async clearStatus() {
      this.statusArray = []
      this.statuses.forEach((el) => (el.isClicked = false))
      this.setHistoryIndex([])
      await this.loadHistoryPaymentIndex()
    },

    async clearPayment() {
      this.methodArray = []
      this.payMethods.forEach((el) => (el.isClicked = false))
      this.setHistoryIndex([])
      await this.loadHistoryPaymentIndex()
    },

    formatDate(date, format) {
      return moment(date).format(format)
    },

    onPageChange(page) {
      this.page = page
      this.loadHistoryPaymentIndex()
    },

    async onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.setHistoryIndex([])
      await this.loadHistoryPaymentIndex()
    },

    resetState() {
      this.page = 1
      this.lastPage = 1
      this.statusArray = []
      this.methodArray = []
      this.dates = []
      this.statuses.forEach((el) => (el.isClicked = false))
      this.payMethods.forEach((el) => (el.isClicked = false))
      this.dateFrom = null
      this.dateTo = null
      this.closeFilter()
      this.setHistoryIndex([])
      this.loadHistoryPaymentIndex()
    },

    async searchIconClick() {
      await this.resetState()
    },

    async loadHistoryPaymentIndex() {
      this.isLoading = true
      let params = [
        ...this.getParams({
          page: this.page,
          perPage: this.perPage,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          type: 'payment',
          additionalFilters: [this.statusArray, this.methodArray],
        }),
      ]
      params.push(`search=${this.search}`)

      try {
        const response = await this.fetchHistoryIndex(params.join('&'))

        if (response && response.status === 200) {
          this.data = response.data
        }
      } catch (e) {
        console.log(e)
      }
      this.isLoading = false
    },

    openFilter() {
      this.isFilterOpen = true
    },

    closeFilter() {
      this.isFilterOpen = false
    },

    filterByStatus(event) {
      let index = event.s
      const value = this.statusArray.indexOf(
        `filtering_conditions[status][]=${event.status.value}`
      )
      if (value > -1) {
        this.statusArray.splice(value, 1)
        this.statuses[index].isClicked = false
      } else {
        this.statusArray.push(
          `filtering_conditions[status][]=${event.status.value}`
        )
        this.statuses[index].isClicked = true
      }
    },

    filterByPayment(event) {
      let index = event.m
      const value = this.methodArray.indexOf(
        `filtering_conditions[payment_method][]=${event.method.value}`
      )
      if (value > -1) {
        this.methodArray.splice(value, 1)
        this.payMethods[index].isClicked = false
      } else {
        this.methodArray.push(
          `filtering_conditions[payment_method][]=${event.method.value}`
        )
        this.payMethods[index].isClicked = true
      }
    },

    async filterByDate() {
      this.dateFrom =
        this.dates.length !== 0
          ? moment(this.dates[0]).format('YYYY-MM-DD')
          : null
      this.dateTo =
        this.dates.length !== 0
          ? moment(this.dates[1]).format('YYYY-MM-DD')
          : null

      this.setHistoryIndex([])
      await this.loadHistoryPaymentIndex()
    },
  },
}
</script>
