<template>
  <r-card v-if="isFilterOpen" class="filter-history">
    <div class="level mb-0">
      <div class="level-left">
        <h4 class="filter-history__title">Filter</h4>
      </div>
      <div class="level-right">
        <b-icon
          icon="close"
          @click.native="closeFilter"
          class="pointer-click"
          size="is-small"
        />
      </div>
    </div>
    <div class="columns is-multiline">
      <div class="column is-12 pb-0 filter-history__subtitle">
        Filter by Status
        <hr class="hr-class" />
      </div>
      <div
        v-for="(status, s) in statuses"
        :key="`status-${s}`"
        class="column pt-0 is-4 filter-history__button-container"
      >
        <b-button
          class="filter-history__button"
          :class="status.isClicked ? 'filter-button-clicked' : ''"
          :type="status.isClicked ? 'is-danger' : 'is-light'"
          @click.native="filterByStatus({ status, s })"
        >
          {{ status.title }}
        </b-button>
      </div>
      <div class="column is-12 pb-0 filter-history__subtitle">
        Filter by Payment Method
        <hr class="hr-class" />
      </div>
      <div
        v-for="(method, m) in payMethods"
        :key="`method-${m}`"
        class="column pt-0 is-4"
      >
        <b-button
          class="filter-history__button"
          :class="method.isClicked ? 'filter-button-clicked' : ''"
          :type="method.isClicked ? 'is-danger' : 'is-light'"
          @click.native="filterByPayment({ method, m })"
        >
          {{ method.title }}
        </b-button>
      </div>
    </div>
    <div class="level mt-4">
      <div class="level-left">
        <div class="pointer-click" @click="resetState">Reset</div>
      </div>
      <div class="level-reset">
        <b-button
          label="Show Result"
          @click.native="loadHistoryPaymentIndex"
        ></b-button>
      </div>
    </div>
  </r-card>
</template>
<script>
export default {
  props: {
    isFilterOpen: {
      default: false,
      type: Boolean,
    },
    statuses: {
      default: null,
      type: Array,
    },
    payMethods: {
      default: null,
      type: Array,
    },
  },

  methods: {
    closeFilter() {
      this.$emit('closeFilter')
    },
    filterByStatus(event) {
      this.$emit('filterStatus', event)
    },
    filterByPayment(event) {
      this.$emit('filterPaymentMethod', event)
    },
    resetState() {
      this.$emit('resetState')
    },
    loadHistoryPaymentIndex() {
      this.$emit('loadData')
    },
  },
}
</script>
